@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
.permission-access-hide {
  display: none !important; }

.error-snackbar {
  background: #ff5a5a !important;
  color: white !important; }

.error-snackbar .mat-button-wrapper {
  color: white !important; }

.success-snackbar {
  background: #5f9e64 !important;
  color: white !important; }

.success-snackbar .mat-button-wrapper {
  color: white !important; }

.warn-snackbar {
  background: #ffd84c !important;
  color: black !important; }

.warn-snackbar .mat-button-wrapper {
  color: black !important; }

.waiting-snackbar {
  background: #fcae213d !important;
  color: black !important; }

.waiting-snackbar .mat-button-wrapper {
  color: black !important; }

.logo-blue-btn {
  width: 100%;
  height: 43px;
  background-color: #01abcc; }

.wait {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px; }
  .wait div {
    padding-right: 10px; }

.auth-login-register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  position: relative; }

.logo-container {
  position: absolute;
  top: 20px;
  left: 20px; }

.logo {
  max-width: 150px;
  height: auto; }

.social-logo {
  margin-right: 10px; }

.auth-heading {
  font-weight: 600;
  font-size: 1.8em;
  color: black;
  text-align: center;
  margin-bottom: 2.5rem; }

.btn-white {
  background-color: #fbfbfb;
  border-color: #6d6e6d;
  color: #646464; }

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
  color: #666;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase; }
  .divider::before, .divider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #d1d1d1;
    margin: 0 10px; }

.login-link {
  margin-top: 24px;
  font-size: 1rem;
  color: black;
  text-align: center; }
  .login-link a {
    color: #10a37f; }

.footer-links a {
  font-weight: 400;
  text-decoration: inherit;
  color: #10a37f;
  font-size: 1rem;
  margin: 5px; }

.auth-link {
  font-weight: 400;
  text-decoration: inherit;
  color: #10a37f;
  font-size: 1rem;
  margin: 5px; }

.form-control {
  border: 0.5px solid #9b9696 !important; }

.password-requirements {
  margin-top: 10px;
  font-size: 14px;
  color: #666; }

.password-requirements p {
  margin-bottom: 5px; }

.password-requirements ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.password-requirements li {
  display: flex;
  align-items: center;
  gap: 5px; }

.password-requirements li::before {
  content: '✗';
  color: #ff0000; }

.password-requirements li.valid::before {
  content: '✓';
  color: #00c4b4; }

.password-requirements {
  padding: 11px;
  border: 1px solid #c0c0c0;
  border-radius: 3px;
  margin-top: 12px; }

.disabled {
  background-color: #ededed !important; }

.go-back {
  text-align: center;
  margin-top: 10px;
  font-weight: 1000; }
